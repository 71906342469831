import React from 'react'
import { Router, Route } from 'react-router-dom'
import MainProvider from 'commons/providers/MainProvider'
import SnackbarDefault from 'views/snackbar/snackbar'
import { SnackbarProvider } from 'views/snackbar/snackbar-context'
import Main from 'views/main'
import history from './history'

function App() {
  return (
    <MainProvider>
      <SnackbarProvider>
        <Router history={history}>
          <Route path="/" component={Main} />
        </Router>
        <SnackbarDefault />
      </SnackbarProvider>
    </MainProvider>
  )
}

export default App
