import React from 'react'
import ReactDOM from 'react-dom'
import registerServiceWorker from './registerServiceWorker'
import App from './app'
import 'assets/fonts/roboto.css'

// if (process.env.NODE_ENV === 'development') {
// const { worker } = require('./mocks/config/browser')
// worker.start()
// }

ReactDOM.render(<App />, document.getElementById('root'))

registerServiceWorker()
